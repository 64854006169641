import styled from '@emotion/styled';

type ShapeProps = {
  height: number;
  color: string;
};

export const Shape = styled.div`
  ${(props: ShapeProps) => `
    height: ${props.height}px;
    &:before {
      bottom: 0;
      content: '';
      border-radius: 100%;
      position: absolute;
      right: -${props.height}px;
      left: -${props.height}px;
      top: -${props.height}px;
      background-color: ${props.color};
    }
  `}
`;
