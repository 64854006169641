import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

import { ReviewCard } from '../ReviewCard';

export const Shape = styled.div`
  min-height: 24rem;
  max-width: 35rem !important;
`;

export const Line = styled.span`
  height: 0.5rem;
`;

export const Cover = styled.img`
  height: 8rem;
  object-fit: cover;
`;

export const Reviews = styled.div`
  opacity: 0.4;
  filter: grayscale(100%);
  top: 3.125rem;

  @media only screen and (max-width: ${BREAKPOINTS.sm}) {
    display: none !important;
  }
`;

export const ReviewCardWrapper = styled(ReviewCard)`
  max-width: 16.25rem !important;
`;

export const Review = styled.div`
  display: flex;
  opacity: 0.4;

  &:nth-of-type(1) {
    .review-card {
      margin-left: 15vw;
    }
  }

  &:nth-of-type(2) {
    .review-card {
      margin-right: 12vw;
    }
  }

  &:nth-of-type(3) {
    .review-card {
      margin-left: 9vw;
    }
  }

  &:nth-of-type(4) {
    .review-card {
      margin-right: 6vw;
    }
  }

  &:nth-of-type(5) {
    .review-card {
      margin-left: 5vw;
    }
  }

  &:nth-of-type(6) {
    .review-card {
      margin-right: 8vw;
    }
  }

  &:nth-of-type(7) {
    .review-card {
      margin-left: 9vw;
    }
  }

  &:nth-of-type(8) {
    .review-card {
      margin-right: 8vw;
    }
  }

  &:nth-of-type(9) {
    .review-card {
      margin-left: 5vw;
    }
  }

  &:nth-of-type(10) {
    .review-card {
      margin-right: 9vw;
    }
  }

  &:nth-of-type(11) {
    .review-card {
      margin-left: 14vw;
    }
  }

  &:nth-of-type(12) {
    .review-card {
      margin-right: 15vw;
    }
  }

  &:nth-of-type(2),
  &:nth-of-type(4),
  &:nth-of-type(6),
  &:nth-of-type(8),
  &:nth-of-type(10),
  &:nth-of-type(12) {
    justify-content: end;
  }
`;

export const Work = styled.div`
  height: 10rem;

  img {
    object-fit: cover;
  }

  &:nth-of-type(3) img {
    margin-top: -2rem;
  }
`;
