import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';
import Link from 'next/link';

import { FreeIllustration } from './components/FreeIllustration';

export const StyledFreeIllustration = styled(FreeIllustration)`
  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    margin-top: -3rem;
    z-index: 1;
  }
`;

export const DashedLine = styled.div`
  &:before {
    @media only screen and (min-width: ${BREAKPOINTS.xs}) {
      top: 0;
      content: ' ';
      height: 3.5rem;
      position: absolute;
      transform: translate(0, -3.8rem);
      border: 1px dashed ${COLORS.SECONDARY_300};
    }

    @media only screen and (min-width: ${BREAKPOINTS.sm}) {
      height: 6.25rem;
      transform: translate(0, -6.5rem);
    }
  }
`;

export const ModuleTitle = styled.h2`
  font-size: 3.5rem;
`;

export const TitleWithDote = styled.p`
  &:after {
    content: '.';
    color: ${COLORS.PRIMARY_500};
  }
`;

export const SortlistPlusLogoWrapper = styled.div`
  &:before {
    @media only screen and (min-width: ${BREAKPOINTS.xs}) {
      border: 1px dashed ${COLORS.SECONDARY_300};
      content: ' ';
      position: absolute;
      top: 0;
      left: 50%;
      height: 9.25rem;
      transform: translate(-50%, -9.5rem);
    }
  }
`;

export const VisibilityOpportunityWrapper = styled.div`
  @media only screen and (min-width: ${BREAKPOINTS.xs}) {
    background-color: ${COLORS.SECONDARY_100};
  }
`;

export const VisibilityOpportunitySubtitle = styled.p`
  font-size: 1.75rem;
  line-height: 1.2;
`;

export const AddWrapper = styled.div`
  transform: translateY(50%);
`;
