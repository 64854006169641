import {
  Bolt,
  EmojiEventsTwoTone,
  EmojiObjectsTwoTone,
  PeopleAltTwoTone,
  PowerTwoTone,
  StarTwoTone,
  TrendingUpTwoTone,
} from '@mui/icons-material';
import { TYPOGRAPHY } from '@sortlist-frontend/design-system';
import { TFunction } from '@sortlist-frontend/translation/ssr';
import { ReactElement } from 'react';

export type BenefitType = {
  id: string;
  icon: ReactElement;
  title: string;
  items?: string[];
};

export const getFreeBenefits = (t: TFunction): BenefitType[] => {
  return [
    {
      id: 'generate-brand-awareness',
      icon: <Bolt style={{ fontSize: TYPOGRAPHY.H4 }} />,
      title: t('providers:freeSection.advantages.0.title'),
      items: [
        t('providers:freeSection.advantages.0.items.0'),
        t('providers:freeSection.advantages.0.items.1'),
        t('providers:freeSection.advantages.0.items.2'),
      ],
    },
    {
      id: 'build-trust-with-your-audience',
      icon: <PeopleAltTwoTone style={{ fontSize: TYPOGRAPHY.H4 }} />,
      title: t('providers:freeSection.advantages.1.title'),
      items: [
        t('providers:freeSection.advantages.1.items.0'),
        t('providers:freeSection.advantages.1.items.1'),
        t('providers:freeSection.advantages.1.items.2'),
      ],
    },
    {
      id: 'collect-review',
      icon: <StarTwoTone style={{ fontSize: TYPOGRAPHY.H4 }} />,
      title: t('providers:freeSection.advantages.2.title'),
      items: [t('providers:freeSection.advantages.2.items.0'), t('providers:freeSection.advantages.2.items.1')],
    },
  ];
};

export const getSortlistPlusBenefits = (t: TFunction): BenefitType[] => {
  return [
    {
      id: 'boost-your-online-reputation',
      icon: <EmojiEventsTwoTone style={{ fontSize: TYPOGRAPHY.H4 }} />,
      title: t('providers:SortlistPlusSection.benefits.0.title'),
      items: [
        t('providers:SortlistPlusSection.benefits.0.items.0'),
        t('providers:SortlistPlusSection.benefits.0.items.1'),
        t('providers:SortlistPlusSection.benefits.0.items.2'),
      ],
    },
    {
      id: 'drive-more-conversions',
      icon: <TrendingUpTwoTone style={{ fontSize: TYPOGRAPHY.H4 }} />,
      title: t('providers:SortlistPlusSection.benefits.1.title'),
      items: [
        t('providers:SortlistPlusSection.benefits.1.items.0'),
        t('providers:SortlistPlusSection.benefits.1.items.1'),
        t('providers:SortlistPlusSection.benefits.1.items.2'),
      ],
    },
    {
      id: 'media-and-insights-addons',
      icon: <PowerTwoTone style={{ fontSize: TYPOGRAPHY.H4 }} />,
      title: t('providers:SortlistPlusSection.benefits.2.title'),
      items: [
        t('providers:SortlistPlusSection.benefits.2.items.0'),
        t('providers:SortlistPlusSection.benefits.2.items.1'),
        t('providers:SortlistPlusSection.benefits.2.items.2'),
      ],
    },
  ];
};
