import { AnalyticsProvider, TrackingProvider } from '@sortlist-frontend/tracking';
import { useTranslation } from '@sortlist-frontend/translation/ssr';
import { dehydrate, DehydratedState, QueryClient } from '@tanstack/react-query';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import { Fragment } from 'react/jsx-runtime';

import { PageHead } from '_components/base/head';
import { LayoutPagesRouter } from '_components/layout/LayoutPagesRouter';
import { GenericPageSeo } from '_components/seo/generic-page-seo';
import { cacheConfig } from '_config/cache.config';
import { extractDomainRelevantInfo } from '_config/domain.config';
import { getSeoImage } from '_config/next-seo.config';
import { AppProviders } from '_core/app-providers';
import { PublicAppContextProvider } from '_core/context/public-app-context';
import { PublicApiRepo } from '_core/repos/public-api.repo';
import { baseSsrPrefetchQueries } from '_core/utils/public-api';
import { getServerSideTranslations } from '_core/utils/ssr';
import ProvidersPage from '_features/Providers';
import { providersConfig } from '_features/Providers/config';

type Prop = {
  locale: string;
  canonical: string;
  origin: string;
  publicApiNavBarUrl: string;
  dehydratedState: DehydratedState;
};

export default function PageRoute(props: InferGetServerSidePropsType<typeof getServerSideProps>) {
  return (
    <AppProviders dehydratedState={props.dehydratedState}>
      <Route {...props} />
    </AppProviders>
  );
}

const Route = (props: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  const { canonical, origin, locale = 'en', publicApiNavBarUrl } = props;
  const { t } = useTranslation(providersConfig.i18nNamespaces);
  const seoData = {
    title: t('agency:seo.apply.title'),
    description: t('agency:seo.apply.description_V2'),
    image: getSeoImage('default'),
    canonical,
  };

  PublicApiRepo.publicApiNavBarUrl = publicApiNavBarUrl;

  return (
    <Fragment>
      <PageHead />
      <PublicAppContextProvider
        canonical={canonical}
        origin={origin}
        locale={locale}
        briefingOptions={{
          page: 'providers',
        }}>
        <AnalyticsProvider>
          <TrackingProvider app="appPublic" category="providers-home" name="agency-marketing">
            <LayoutPagesRouter>
              <GenericPageSeo {...seoData} />
              <ProvidersPage lang={locale} origin={origin} />
            </LayoutPagesRouter>
          </TrackingProvider>
        </AnalyticsProvider>
      </PublicAppContextProvider>
    </Fragment>
  );
};

export const getServerSideProps: GetServerSideProps<Prop> = async (context) => {
  const { origin: originalUrl, lang } = context.query;
  const { canonical, origin, locale, redirectLocale, redirectNoLocale } = extractDomainRelevantInfo(context, lang);

  if (redirectLocale && !originalUrl) {
    context.res.writeHead(302, {
      Location: `/${locale}${context.resolvedUrl}`,
    });
    context.res.end();
  }
  if (redirectNoLocale && !originalUrl) {
    context.res.writeHead(302, {
      Location: `${context.resolvedUrl}`,
    });
    context.res.end();
  }

  const queryClient = new QueryClient();
  let publicApiNavBarUrl = '';

  try {
    const response = await baseSsrPrefetchQueries(origin, locale, context.resolvedUrl, queryClient);
    publicApiNavBarUrl = response.publicApiNavBarUrl;
  } catch (e) {
    // Limitation: Typescript exceptions are not typed, so we rely on what is supposed to happen
    if ((e as any).status !== 404) {
      throw e;
    }
  }

  const cacheOptions = cacheConfig.pages['staticPages'];
  if (!context.res.headersSent && cacheOptions != null) {
    context.res.setHeader('Cache-Control', cacheOptions.httpCacheControl);
  }

  const { i18nNamespaces } = providersConfig;
  return {
    props: {
      locale,
      canonical,
      origin,
      publicApiNavBarUrl,
      dehydratedState: dehydrate(queryClient),
      ...(await getServerSideTranslations(locale, i18nNamespaces)),
    },
  };
};
