import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

type IllustrationWrapperProps = {
  color: string;
};

export const IllustrationWrapper = styled.div<IllustrationWrapperProps>`
  ${(props) => `
    min-width: 30rem;
    min-height: 40rem;

    &:before {
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      content: '';
      position: absolute;
      z-index: 2;
      box-shadow: inset 0px 0px 3rem 2rem ${props.color};
    }
    @media only screen and (max-width: ${BREAKPOINTS.xs}) {
      min-width: 100%;
    }
  `}
`;

export const Grid = styled.div`
  left: 50%;
  top: 50%;
  opacity: 0.5;
  margin: auto;
  min-width: 40rem;
  position: absolute;
  transform: translate(-50%, -50%);
`;

export const AgencyCard = styled.div`
  z-index: 1;
  width: 20rem;

  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    z-index: 2;
  }
`;
