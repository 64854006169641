import styled from '@emotion/styled';

export const Opportunity = styled.div`
  min-width: 40rem;

  &:nth-of-type(1) {
    z-index: 0;
    margin: 0 0 0 5rem;
  }

  &:nth-of-type(2) {
    z-index: 1;
    margin: -5rem 0 0 0;
  }

  &:nth-of-type(3) {
    z-index: 2;
    margin: -3rem 0 0 10rem;
  }
`;
