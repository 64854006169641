import styled from '@emotion/styled';
import { BREAKPOINTS } from '@sortlist-frontend/design-system';

export const Card = styled.main`
  border-width: 1px;
  border-style: solid;
  opacity: 1;
  transform: translateY(0);
  transition: all 0.6s ease-in-out;

  @media only screen and (min-width: ${BREAKPOINTS.sm}) {
    max-height: 24rem;
  }

  img {
    object-fit: cover;
  }

  &.hidden {
    transform: translateY(50%);
    opacity: 0;
  }
`;
