import styled from '@emotion/styled';
import { Button } from '@sortlist-frontend/design-system';

export const Tag = styled(Button)`
  padding: 0.125rem !important;
  border-radius: 2.5rem;

  &.cursor-auto {
    cursor: auto;
  }
`;
