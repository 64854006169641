import styled from '@emotion/styled';
import { BREAKPOINTS, COLORS } from '@sortlist-frontend/design-system';

export const MainTitle = styled.h1`
  font-size: 4rem;
  line-height: 1;
  &:after {
    content: '.';
    color: ${COLORS.PRIMARY_500};
  }

  @media only screen and (max-width: ${BREAKPOINTS.xs}) {
    font-size: 2rem;
  }
`;
