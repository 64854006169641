import { Button, COLORS } from '@sortlist-frontend/design-system';
import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { SectionShape } from '_components/common/SectionShape';
import { usePublicAppContext } from '_core/context/public-app-context';
import * as S from '_features/Pricing/components/Header/styles';

type Props = {
  className?: string;
  title: string | ReactNode;
  subtitle?: string;
  ctaLabel?: string;
};

export const Header: FC<Props> = (props) => {
  const { className, title, subtitle, ctaLabel } = props;
  const { getUrl } = usePublicAppContext();
  const agencyClaimLandingUrl = getUrl('agency.claim.landing');

  return (
    <>
      <header data-testid="providers-header" className={clsx(className)}>
        <div className="container-lg text-center pt-56 pb-64 pb-gt-xs-32 pb-gt-sm-64 pt-gt-sm-128">
          <S.MainTitle className="text-neutral-100 bold serif">{title}</S.MainTitle>
          {subtitle && <h6 className="text-secondary-500 mt-16">{subtitle}</h6>}
          {ctaLabel && (
            <Button
              label={ctaLabel}
              size="lg"
              buttonVariant="raised"
              buttonStyle="secondary"
              className="mt-32 border-none"
              onClick={() => (window.location.href = agencyClaimLandingUrl)}
              id="open-agency-claim-btn"
            />
          )}
        </div>
      </header>
      <SectionShape color={COLORS.SECONDARY_900} height={148} className="hide-xs bg-secondary-100" />
    </>
  );
};
