import { AddRounded, ArrowDownwardRounded, ArrowForwardOutlined, Check } from '@mui/icons-material';
import { Button, TYPOGRAPHY, useMediaQuery } from '@sortlist-frontend/design-system';
import { ImageSourceHelper } from '@sortlist-frontend/media';
import { Trans, useTranslation } from '@sortlist-frontend/translation/ssr';
import clsx from 'clsx';

import { ClickableTag } from '_components/common/ClickableTag';
import { QuoteCard } from '_components/common/QuoteCard';
import { usePublicAppContext } from '_core/context/public-app-context';
import { onImageError } from '_core/media/get-media.utils';
import { agencyQuotes, getFakeAgencies } from '_data/index';
import { providersConfig } from '_features/Providers/config';

import { BenefitsCards, Header } from './components';
import { getFreeBenefits, getSortlistPlusBenefits } from './data';
import * as S from './styles';
import { getPricingModules, PricingModule } from './utils';

type Props = {
  lang: string;
  origin: string;
};

export default function Providers(props: Props) {
  const { t } = useTranslation(providersConfig.i18nNamespaces);
  const { getUrl } = usePublicAppContext();
  const { media } = useMediaQuery();
  const providersPricingUrl = getUrl('providers.pricing');
  const agencyClaimLandingUrl = getUrl('agency.claim.landing');

  const modules = getPricingModules(t, media, getUrl);
  const agency = getFakeAgencies(t).find((agency) => agency.id === 'yourCompany') || getFakeAgencies(t)[0];

  const freeBenefits = getFreeBenefits(t);
  const SortlistPlusBenefits = getSortlistPlusBenefits(t);

  return (
    <>
      <Header
        className="bg-secondary-900"
        title={t('providers:header.title')}
        subtitle={t('providers:header.subtitle')}
        ctaLabel={t('providers:header.ctaLabel_V2')}
      />
      <main>
        <section data-testid="providers-free-section" className="bg-secondary-100 pb-64">
          <div className="py-64 py-gt-xs-0 layout-column layout-align-center-center container-lg relative">
            <S.StyledFreeIllustration agency={agency} className="mb-64" />

            <div className="relative text-center layout-column layout-align-center-center width-100">
              <S.DashedLine className="rounded bg-primary-200 text-primary-500 p-8 layout-column layout-align-center-center mb-32">
                <ArrowDownwardRounded style={{ fontSize: TYPOGRAPHY.H4 }} />
              </S.DashedLine>
              <h2 className="h1 bold text-secondary-900 mb-16 lh-1">
                {
                  <Trans i18nKey={'providers:freeSection.title'}>
                    Start up with a <span className="text-primary-500">free profile</span>
                  </Trans>
                }
              </h2>
              <p className="h4 text-secondary-500 mb-32">{t('providers:freeSection.subtitle')}</p>

              <BenefitsCards className="my-32" isFree={true} benefits={freeBenefits} />
              <Button
                label={
                  <Trans i18nKey={'providers:freeSection.ctaLabel_V2'}>
                    Create my <span className="bold">free</span> profile
                  </Trans>
                }
                size="lg"
                buttonVariant="raised"
                buttonStyle="primary"
                onClick={() => (window.location.href = agencyClaimLandingUrl)}
                iconRight={<ArrowForwardOutlined />}
                id="open-claim-btn"
              />
            </div>
          </div>
        </section>
        <section data-testid="providers-sortlist-plus-section" className="bg-neutral-100 pt-96">
          <div className="container-md text-center relative">
            <S.SortlistPlusLogoWrapper className="monogram-sortlist-plus">
              <img
                src={ImageSourceHelper.fromPath('/_img/logos/sortlist/sortlist-plus-primary-negative.svg')}
                loading={'eager'}
                alt="Sortlist Plus logo"
                onError={onImageError}
                className="mb-16"
              />
            </S.SortlistPlusLogoWrapper>
            <S.ModuleTitle className="bold">
              <Trans i18nKey={'providers:SortlistPlusSection.title'}>
                <span className="serif">Generate more business by becoming Sortlist</span>+
              </Trans>
            </S.ModuleTitle>
            <p className="mt-16 h4 text-secondary-500 lh-2">
              <Trans i18nKey={'providers:SortlistPlusSection.subtitle'}>
                Get advanced features out of the box <span className="bold text-primary-900">starting at €99</span>
                /month
              </Trans>
            </p>
          </div>
          <div className="container-lg">
            <BenefitsCards className="my-32" benefits={SortlistPlusBenefits} />
          </div>
        </section>

        <section className="container-lg px-xs-0 relative">
          <S.AddWrapper className="layout-row layout-align-center-center">
            <div className="layout-column layout-align-center-center rounded text-neutral-100 bg-secondary-900 p-16">
              <AddRounded />
            </div>
          </S.AddWrapper>
          <S.VisibilityOpportunityWrapper className="mb-56 rounded-lg py-96">
            <header className="text-center px-xs-16">
              <p className="h4">{t('providers:modules.subTitle')}</p>
              <p className="text-primary-900 bold h2 mt-4">{t('providers:modules.title')}</p>
            </header>
            <div className="mt-32 mt-gt-sm-64">
              {modules.map((module: PricingModule) => {
                const { id, inverted, palette, name, icon, title, description, illustration, items, ctaLink } = module;

                return (
                  <section
                    data-testid="providers-modules-sections"
                    key={id}
                    id={module.id}
                    className={clsx({ 'bg-secondary-100': !inverted }, 'py-xs-32 overflow-hidden')}>
                    <div className="layout-column layout-gt-sm-row layout-align-start-center flex">
                      <div
                        className={clsx(
                          inverted ? 'flex-order-1' : 'flex-order-gt-sm-0',
                          'flex-gt-sm-50 flex-100 layout-column layout-align-center-start text-left p-32 ',
                        )}>
                        <ClickableTag palette={palette} label={name} icon={icon} />
                        <S.TitleWithDote className="h1 bold mb-16">{title}</S.TitleWithDote>
                        <p className="h6 lh-3 mb-16 text-secondary-700">{description}</p>
                        {items && (
                          <ul className="mb-32 layout-column layout-align-start-start list-reset flex">
                            {items.map((item: string, index: number) => (
                              <li
                                key={'Advantage-' + id + '-item-' + index}
                                className={clsx('py-4 layout-row layout-align-start-center')}>
                                <Check style={{ fontSize: TYPOGRAPHY.P }} className="text-success-500 mr-8 my-4" />
                                <span className="text-secondary-500 lh-2 text-left">{item}</span>
                              </li>
                            ))}
                          </ul>
                        )}
                        <Button
                          className="underline"
                          label={t('providers:learnMore')}
                          buttonVariant="default"
                          buttonStyle="secondary"
                          size="md"
                          onClick={() => (window.location.href = ctaLink)}
                        />
                      </div>
                      <div
                        className={clsx(
                          inverted
                            ? 'flex-order-gt-xs-0 layout-align-gt-xs-center-end'
                            : 'flex-order-gt-xs-1 layout-align-gt-xs-center-start',
                          'flex-order-xs-0 flex-gt-sm-50 flex-100 layout-column p-32 layout-align-center-center',
                        )}>
                        {illustration}
                      </div>
                    </div>
                  </section>
                );
              })}
            </div>
          </S.VisibilityOpportunityWrapper>
        </section>

        <section className="testimonials bg-primary-900 py-64">
          <div
            data-testid="providers-testimonials"
            className="text-center bg-content-700 layout-column layout-align-center-center">
            <div className="container-lg">
              <QuoteCard quote={agencyQuotes(t)[9]} />
              <p className="h1 text-neutral-100 mt-56 mb-8 bold">{t('providers:cta.title')}</p>
              <p className="h6 text-secondary-300 mb-24">{t('providers:cta.subTitle_V2')}</p>
              <Button
                label={t('providers:cta.ctaLabel')}
                buttonVariant="raised"
                buttonStyle="secondary"
                size="md"
                onClick={() => (window.location.href = providersPricingUrl)}
                iconRight={<ArrowForwardOutlined />}
                id="open-pricing-btn"
              />
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
